function defaultCompareOrder(a, b) {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
}

exports.defaultCompareOrder = defaultCompareOrder;